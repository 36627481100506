var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "page-title-bar" }, [
      _c("i", { staticClass: "ico ico-user" }),
      _vm._v(_vm._s(_vm.$t("My Account")))
    ]),
    _c(
      "div",
      { staticClass: "myInfo mt-10" },
      [
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "form-input",
                  attrs: {
                    value: _vm.accountId,
                    label: _vm.Label_Id,
                    placeholder: _vm.$t("Input the ID"),
                    clearable: "",
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    disabled: ""
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-form",
          { ref: "password" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        label: _vm.Label_Name,
                        placeholder: _vm.$t("Input the name"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.formData["firstName"],
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "firstName", $$v)
                        },
                        expression: "formData['firstName']"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("v-text-field", {
                      ref: "email",
                      staticClass: "form-input",
                      attrs: {
                        rules: [_vm.rules.email],
                        label: _vm.Label_Email,
                        placeholder: _vm.$t("Input email address"),
                        clearable: "",
                        outlined: "",
                        dense: "",
                        "hide-details": ""
                      },
                      on: {
                        input: _vm.handleEmailChange,
                        keyup: function($event) {
                          return _vm.handleEmailChange(
                            _vm.formData["emailAddress"]
                          )
                        }
                      },
                      model: {
                        value: _vm.formData["emailAddress"],
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "emailAddress", $$v)
                        },
                        expression: "formData['emailAddress']"
                      }
                    }),
                    _c("v-switch", {
                      staticClass: "form-switch",
                      staticStyle: { display: "none" },
                      attrs: {
                        label: _vm.Label_Alarm,
                        inset: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.formData["emailAlarm"],
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "emailAlarm", $$v)
                        },
                        expression: "formData['emailAlarm']"
                      }
                    }),
                    _c("v-switch", {
                      staticClass: "form-switch",
                      attrs: {
                        label: _vm.Label_Report,
                        inset: "",
                        "hide-details": ""
                      },
                      on: { change: _vm.handleEmailReportChange },
                      model: {
                        value: _vm.formData["emailReport"],
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "emailReport", $$v)
                        },
                        expression: "formData['emailReport']"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", [
                  _c("p", { staticClass: "label-txt" }, [
                    _vm._v(_vm._s(_vm.$t("My Store")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "listBox mt-2" },
                    _vm._l(_vm.formData["managedStores"], function(
                      store,
                      index
                    ) {
                      return _c("ul", { key: index }, [
                        _c("li", {
                          domProps: {
                            textContent: _vm._s(
                              store.name + " (store code: " + store.code + ")"
                            )
                          }
                        })
                      ])
                    }),
                    0
                  )
                ]),
                _c("v-col", [
                  _c("p", { staticClass: "label-txt" }, [
                    _vm._v(_vm._s(_vm.$t("My Level")))
                  ]),
                  _c("div", { staticClass: "listBox mt-2" }, [
                    _c("ul", [
                      _c("li", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.formData["title"] +
                              "(" +
                              _vm.formData["accessLevel"] +
                              ")"
                          )
                        }
                      })
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "table-options" }, [
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "btn",
              attrs: {
                disabled: _vm.validemail || _vm.userInfoSaveBtn,
                text: ""
              },
              on: { click: _vm.updateUserInfo }
            },
            [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", scrollable: "", width: "400" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "btn ml-2",
                              attrs: {
                                text: "",
                                disabled: _vm.userInfoPasswordEditBtn
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(_vm._s(_vm.$t("Change Password")))]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.passwordEditDialog,
                callback: function($$v) {
                  _vm.passwordEditDialog = $$v
                },
                expression: "passwordEditDialog"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "popup add_store_popup" },
                [
                  _c("v-card-title", [
                    _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-user" }),
                      _vm._v(_vm._s(_vm.$t("Change Password")))
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      label: _vm.$t("Current Password") + " *",
                                      placeholder: _vm.$t(
                                        "Input current password"
                                      ),
                                      type: "password",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.oldPassword,
                                      callback: function($$v) {
                                        _vm.oldPassword = $$v
                                      },
                                      expression: "oldPassword"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    ref: "newPassword1",
                                    staticClass: "form-input",
                                    attrs: {
                                      rules: [_vm.rules.newPassword1],
                                      label: _vm.$t("New Password") + " *",
                                      placeholder: _vm.$t("Input new password"),
                                      type: "password",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    on: { keyup: _vm.handleNewPassword1Keyup },
                                    model: {
                                      value: _vm.newPassword1,
                                      callback: function($$v) {
                                        _vm.newPassword1 = $$v
                                      },
                                      expression: "newPassword1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    ref: "newPassword2",
                                    staticClass: "form-input",
                                    attrs: {
                                      rules: [_vm.rules.newPassword2],
                                      label: _vm.$t("Verify Password") + " *",
                                      placeholder: _vm.$t("Input new password"),
                                      type: "password",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      clearable: ""
                                    },
                                    on: { keyup: _vm.handleNewPassword2Keyup },
                                    model: {
                                      value: _vm.newPassword2,
                                      callback: function($$v) {
                                        _vm.newPassword2 = $$v
                                      },
                                      expression: "newPassword2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: {
                            disabled:
                              _vm.oldPassword === "" ||
                              _vm.newPassword1 === "" ||
                              _vm.newPassword2 === "",
                            text: "",
                            icon: ""
                          },
                          on: { click: _vm.handlePasswordEditClick }
                        },
                        [_vm._v(_vm._s(_vm.$t("Save")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: { click: _vm.cancelPasswordEdit }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      [
        _c(
          "v-dialog",
          {
            attrs: { width: "300" },
            model: {
              value: _vm.successDialog,
              callback: function($$v) {
                _vm.successDialog = $$v
              },
              expression: "successDialog"
            }
          },
          [
            _c(
              "v-card",
              { staticClass: "popup add_store_popup" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.message) }
                      })
                    ])
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: {
                          click: function($event) {
                            _vm.successDialog = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("OK")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }